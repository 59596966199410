import { Button, makeStyles, TextField, Typography } from '@material-ui/core'
import { useCallback, useEffect, useRef } from 'react'
import * as api from './api'
import { useSession } from './SessionProvider'

const SignIn = () => {
    useDocumentTitle('ログイン')
    const {fetchSessionAsync} = useSession()
    const email = useRef()
    const password = useRef()
    const handleSubmit = useCallback(async evt => {
        evt.preventDefault()
        console.log({email: email.current.value, password: password.current.value})
        const {ok} = await api.createSession(email.current.value, password.current.value)
        if (!ok) {
            return false
        }
        fetchSessionAsync()
        return false
    }, [fetchSessionAsync])
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <form className={classes.form} onSubmit={handleSubmit} >
                <Typography className={classes.title} variant="h4">ログイン</Typography>
                <TextField className={classes.textField} type="email" label="ID(メールアドレス)" variant="outlined" inputRef={email} required />
                <TextField className={classes.textField} type="password" label="パスワード" variant="outlined" inputRef={password} required />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                >ログイン</Button>
            </form>
        </div>
    )
}

export default SignIn

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
    },
    form: {
        position: 'absolute',
        top: '25vh',
        left: '25vw',
        width: '50vw',
        height: '50vh',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 10,
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textField: {
        width: '30vw',
        marginBottom: theme.spacing(3),
    },
    title: {
        marginBottom: theme.spacing(3),
    },
}))

const useDocumentTitle = title => {
    useEffect(() => {
        const oldTitle = document.title
        document.title = title
        return () => document.title = oldTitle
    }, [title])
}
