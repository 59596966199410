import { AppBar as MuiAppBar, Hidden, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import { createContext, useCallback, useEffect, useState } from 'react'
import { useNavigation } from './navigation'

const AppBar = () => {
    const { title, openDrawer } = useNavigation()
    const handleClickMenu = useCallback(() => {
        openDrawer()
    }, [openDrawer])
    const classes = useStyles()
    return (
        <MuiAppBar className={classes.root}>
            <Toolbar className={classes.toolbar}>
                <Hidden mdUp>
                    <IconButton
                        color="secondary"
                        onClick={handleClickMenu}
                        className={classes.menuButton}
                    >
                        <Menu />
                    </IconButton>
                </Hidden>
                <Typography
                    variant="h6"
                    className={classes.title}
                >{title}</Typography>
            </Toolbar>
        </MuiAppBar >
    )
}

export default AppBar

const useStyles = makeStyles(theme => ({
    root: {
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
    },
    toolbar: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 250 + theme.spacing(2),
        },
    },
}))

const context = createContext()

export const AppBarProvider = ({ children }) => {
    const [title, setTitle] = useState(document.title)
    const [backURL, setBackURL] = useState(null)
    useEffect(() => {
        document.title = title
    }, [title])
    const value = {
        title,
        setTitle,
        backURL,
        setBackURL,
    }
    return (
        <context.Provider value={value}>
            {children}
        </context.Provider>
    )
}
