import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import * as api from './api'

const context = createContext()

const SessionProvider = props => {
    const [isLoading, setIsLoading] = useState(true)
    const [session, setSession] = useState(null)
    const fetchSessionAsync = useCallback(async () => {
        try {
            setIsLoading(true)
            const { ok, item } = await api.getCurrentSession()
            if (!ok) {
                setSession(null)
                setIsLoading(false)
                return
            }
            setSession(item)
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }
    }, [])
    useEffect(() => {
        fetchSessionAsync()
    }, [fetchSessionAsync])
    const signOutAsync = useCallback(async () => {
        try {
            const { ok } = await api.deleteCurrentSession()
            if (!ok) {
                return {ok: false}
            }
            await fetchSessionAsync()
            return {ok: true}
        } catch(error) {
            return {ok: false, error}
        }
    }, [fetchSessionAsync])
    const ctx = {
        isLoading,
        session,
        fetchSessionAsync,
        signOutAsync,
    }
    const { children } = props
    return (
        <context.Provider value={ctx}>{children}</context.Provider>
    )
}

export default SessionProvider

export const useSession = () => {
    return useContext(context)
}