import ThemeProvider from "./ThemeProvider";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import DefaultApp from "./DefaultApp";
import { GoogleMapProvider } from "./GoogleMap";
import SessionProvider from "./SessionProvider";
import Auth from "./Auth";

function App() {
    return (
        <ThemeProvider>
            <BrowserRouter>
                <SessionProvider>
                    <Auth>
                        <GoogleMapProvider>
                            <Routes />
                        </GoogleMapProvider>
                    </Auth>
                </SessionProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App

const Routes = () => {
    return (
        <Switch>
            <Route path="/" component={DefaultApp} />
        </Switch>
    )
}
