import { Button, makeStyles, TextField } from "@material-ui/core"
import SelectableTextField from "./SelectableTextField"

const SeikyuForm = ({projects, onSubmit}) => {
    const defaultValues = useDefaultValues(projects)
    const classes = useStyles()
    const handleSubmit = evt => {
        console.log('SeikyuForm', {})
        evt.preventDefault()
        try {
            const form = evt.target
            const item = {
                projectIds: projects.map(proj => proj.id),
                kouza: form.kouza.value,
                tantou: form.tantou.value,
                hizuke: form.hizuke.value,
                subject: form.subject.value,
                zipCode: form.zipCode.value,
                address: form.address.value,
            }
            onSubmit(item)
        }catch(error) {
            console.log({error})
        } finally {
            return false
        }
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className={classes.formRow}>
                <TextField variant="outlined" name="subject" label="件名" defaultValue={defaultValues.projectName} />
            </div>
            <div className={classes.formRow}>
                <TextField variant="outlined" name="tantou" label="担当者" defaultValue={defaultValues.tantou} />
            </div>
            <div className={classes.formRow}>
                <TextField variant="outlined" name="hizuke" label="日付" defaultValue={defaultValues.hizuke} />
            </div>
            <div className={classes.formRow}>
                <TextField variant="outlined" name="zipCode" label="郵便番号(ハイフンなし)" defaultValue={defaultValues.zipCode} inputProps={{maxlength: 7}}/>
            </div>
            <div className={classes.formRow}>
                <TextField variant="outlined" name="address" label="住所" defaultValue={defaultValues.address} multiline rows={3} rowsMax={3} />
            </div>
            <div className={classes.formRow}>
                <SelectableTextField
                    variant="outlined"
                    name="kouza"
                    label="振込み先口座"
                    multiline
                    defaultValue={defaultValues.kouza}
                    menuValues={kouzaList}
                />
            </div>
            <div className={classes.formRow}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                >まとめて請求書</Button>
            </div>
        </form>
    )
}

export default SeikyuForm

const useDefaultValues = projects => {
    if (projects.length === 0) {
        return initialValues
    }
    const kouza  = projects[0].kouza
    const tantou = projects[0].tantou
    const hizuke = projects[0].seikyuHizuke
    const zipCode = projects[0].seikyuZipCode
    const address = projects[0].seikyuAddress
    return {
        ...initialValues,
        kouza,
        tantou,
        hizuke,
        zipCode,
        address,
    }
}

const initialValues = {
    kouza: '',
    subject: '',
    tantou: '',
    hizuke: '',
    zipCode: '',
    address: '',
}

const kouzaList = [
    `静清信用金庫 片羽支店
普通 0231969　株式会社吉祥`,
    `静岡銀行 沓谷支店
普通 0928247　株式会社吉祥`,
]

const useStyles = makeStyles(theme => ({
    formRow: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}))