import { createContext, useCallback, useContext, useEffect, useState } from "react";

const context = createContext()

export const NavigationProvider = ({children}) => {
    const [title, setTitle] = useState(document.title)
    const [backURL, setBackURL] = useState(null)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const openDrawer = useCallback(() => setDrawerOpen(true), [])
    const closeDrawer = useCallback(() => setDrawerOpen(false), [])
    const value = {
        drawerOpen,
        openDrawer,
        closeDrawer,
        title,
        setTitle,
        backURL,
        setBackURL,
    }
    useEffect(() => {
        document.title = title
    }, [title])
    return (
        <context.Provider value={value}>
            {children}
        </context.Provider>
    )
}

export const useNavigation = () => useContext(context)

export const useTitle = title => {
    const {setTitle} = useNavigation()
    useEffect(() => {
        setTitle(title)
    }, [setTitle, title])
}

export const useBackUrl = backUrl => {
    const { setBackURL } = useNavigation()
    useEffect(() => {
        setBackURL(backUrl)
        return () => setBackURL(null)
    }, [setBackURL, backUrl])
}