export const print = value => {
    if (!value) {
        return ""
    }
    return words[value] || value
}

const words = {
    "sama": "様",
    "onchu": "御中",
    "kouzou_mokuzou": '木造',
    "kouzou_s": 'S造',
    "kouzou_rc": 'RC造',
    "kouzou_src": "SRC造",
    "kouzou_other": "その他",
    "kaisou_1": "1階建て",
    "kaisou_2": "2階建て",
    "kaisou_3": "3階建て",
    "kaisou_other": "その他",
    "syubetu_senyouJutaku": "専用住宅",
    "syubetu_other": "その他",
    "zenmen_douro_4mUp": "4m以上(車のすれ違い可)",
    "zenmen_douro_other": "その他",
    "rinka_kyori_60cmDown": "60cm未満",
    "rinka_kyori_other": "その他",
    "kasetu_koji": "仮設足場工事費",
    "kaitai_koji": "解体工事費",
    "haiki_shobun": "廃棄処分費",
    "gaiko_tekkyo": "外構撤去及びその他費用",
    "shyokeihi": "諸経費（燃料費・交通費・消耗品・法定福利費等）",
    "hikazei": "非課税項目",
    "nichi": "日",
    "ninku": "人工",
    "jikan": "時間",
    "shiki": "式",
    "m3": "㎥",
    "m2": "㎡",
    "dai": "台",
    "cm": "cm",
    "m": "m",
    "kai": "回",
    "sou": "槽",
    "ko": "個",
    "hon": "本",
    "ki": "基",
    "mai": "枚",
    "kasho": "箇所",
    "kg": "Kg",
    "ton": "t",
    "cm2": "㎠",
    "nin": "人",
    "mei": "名",
}
