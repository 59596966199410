const queryString = require('query-string')


export const getCurrentSession = async () => {
    try {
        const resp = await fetch('/api/sessions/current')
        if (!resp.ok) {
            return {ok: false}
        }
        const item = await resp.json()
        return {ok: true, item}
    } catch (error) {
        return { ok: false, error }
    }
}

export const createSession = async (email, password) => {
    try {
        const resp = await fetch('/api/sessions', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email,
                password,
            })
        })
        if (!resp.ok) {
            return {ok: false}
        }
        return {ok: true}
    } catch(error) {
        return {ok: false, error}
    }
}

export const deleteCurrentSession = async (email, password) => {
    try {
        const resp = await fetch('/api/sessions/current', {
            method: 'DELETE',
        })
        if (!resp.ok) {
            return {ok: false}
        }
        return {ok: true}
    } catch(error) {
        return {ok: false, error}
    }
}

export const createProject = async item => {
    try {
        const resp = await fetch('/api/projects', {
            method: 'POST',
            body: JSON.stringify(item),
        })
        if (!resp.ok) {
            return {ok: false}
        }
        const createdItem = await resp.json()
        return {ok: true, item: createdItem}
    } catch(error) {
        return {ok: false, error}
    }
}

export const getProject = async id => {
    try {
        const resp = await fetch(`/api/projects/${id}`)
        if (!resp.ok) {
            return {ok: false}
        }
        const item = await resp.json()
        return {ok: true, item}
    } catch(error) {
        return {ok: false, error}
    }
}

export const deleteProject = async id => {
    try {
        const resp = await fetch(`/api/projects/${id}`, {
            method: 'DELETE',
        })
        if (!resp.ok) {
            return {ok: false}
        }
        return {ok: true}
    } catch(error) {
        return {ok: false, error}
    }
}

export const updateProject = async (id, item) => {
    try {
        const resp = await fetch(`/api/projects/${id}`, {
            method: 'PUT',
            body: JSON.stringify(item),
        })
        if (!resp.ok) {
            return {ok: false}
        }
        const updatedItem = await resp.json()
        return {ok: true, item: updatedItem}
    } catch(error) {
        return {ok: false, error}
    }
}

export const searchProjects = async opts => {
    try {
        console.log({opts})
        let qStr = queryString.stringify(opts)
        if (qStr) {
            qStr = `?${qStr}`
        }
        console.log(qStr)
        const resp = await fetch(`/api/projects${qStr}`)
        if (!resp.ok) {
            return {ok: false}
        }
        const result = await resp.json()
        return {ok: true, ...result}
    } catch(error) {
        console.log({error})
        return {ok: false, error}
    }
}

export const getCustomerByName = async name => {
    try {
        const resp = await fetch(`/api/customers/name=${encodeURIComponent(name)}`)
        if (!resp.ok) {
            return {ok: false}
        }
        const item = await resp.json()
        return {ok: true, item}
    } catch(error) {
        return {ok: false, error}
    }
}

export const getCustomerById = async id => {
    try {
        const resp = await fetch(`/api/customers/${id}`)
        if (!resp.ok) {
            return {ok: false}
        }
        const item = await resp.json()
        return {ok: true, item}
    } catch(error) {
        return {ok: false, error}
    }
}

export const updateCustomer = async (id, item) => {
    try {
        const resp = await fetch(`/api/customers/${id}`, {
            method: 'PUT',
            body: JSON.stringify(item),
        })
        if (!resp.ok) {
            return {ok: false}
        }
        const updatedItem = await resp.json()
        return {ok: true, item: updatedItem}
    } catch(error) {
        return {ok: false, error}
    }
}

export const searchCustomers = async opts => {
    try {
        console.log({opts})
        let qStr = queryString.stringify(opts)
        if (qStr) {
            qStr = `?${qStr}`
        }
        console.log(qStr)
        const resp = await fetch(`/api/customers${qStr}`)
        if (!resp.ok) {
            return {ok: false}
        }
        const result = await resp.json()
        return {ok: true, ...result}
    } catch(error) {
        console.log({error})
        return {ok: false, error}
    }
}

export const getMitumoriItems = async projectId => {
    try {
        const resp = await fetch(`/api/projects/${projectId}/mitumori/items`)
        if (!resp.ok) {
            return {ok: false}
        }
        const items = await resp.json()
        return {ok: true, items}
    } catch(error) {
        return {ok: false, error}
    }
}

export const getMitumori = async projectId => {
    try {
        const resp = await fetch(`/api/projects/${projectId}/mitumori`)
        if (!resp.ok) {
            return {ok: false}
        }
        const item = await resp.json()
        return {ok: true, item}
    } catch(error) {
        return {ok: false, error}
    }
}

export const saveMitumoriItems = async (projectId, items) => {
    try {
        const resp = await fetch(`/api/projects/${projectId}/mitumori/items`, {
            method: 'PUT',
            body: JSON.stringify(items),
        })
        if (!resp.ok) {
            return {ok: false}
        }
        const savedItems = await resp.json()
        return {ok: true, items: savedItems}
    } catch(error) {
        console.log({error})
        return {ok: false, error}
    }
}

export const saveMitumori = async (projectId, {items, nebiki}) => {
    try {
        const resp = await fetch(`/api/projects/${projectId}/mitumori`, {
            method: 'PUT',
            body: JSON.stringify({items, nebiki}),
        })
        if (!resp.ok) {
            return {ok: false}
        }
        const savedItem = await resp.json()
        return {ok: true, item: savedItem}
    } catch(error) {
        console.log({error})
        return {ok: false, error}
    }
}

export const createSeikyu = async (item) => {
    console.log("createSeikyu", item)
    try {
        const resp = await fetch(`/api/seikyu`, {
            method: 'POST',
            body: JSON.stringify(item),
        })
        if (!resp.ok) {
            return {ok: false}
        }
        return {ok: true, item: await resp.json()}
    } catch(error) {
        console.log({error})
        return {ok: false, error}
    }

}