import { Link, Button, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, TableFooter, TextField } from "@material-ui/core"
import { useTitle } from "./navigation"
import Content from "./Content"
import { Add as AddIcon, Delete, Delete as DeleteIcon } from '@material-ui/icons'
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import * as api from './api'
import { format, parseJSON } from 'date-fns'
import { print } from "./print"
import SeikyuForm from "./SeikyuForm"

const queryString = require('query-string')
const PER_PAGE = 10

const Project = () => {
    useTitle('案件一覧')
    const searchOpts = useSearchOpts()
    const [fetched, setFetched] = useState(false)
    const [result, setResult] = useState({ total: 0, items: [] })
    const { items, total } = result
    const [keyword, setKeyword] = useState(searchOpts.keyword ?? "")
    const fetchAsync = useCallback(async () => {
        try {
            const { ok, items, total } = await api.searchProjects(searchOpts)
            if (ok) {
                setResult({ items, total })
            }
        } catch (error) {
            console.log({ error })
        }
    }, [searchOpts])
    useEffect(() => {
        fetchAsync().then(() => setFetched(true))
    }, [fetchAsync])
    // const [seikyuProjects, setSeikyuProject] = 
    const history = useHistory()
    const handleClickAdd = useCallback(() => {
        history.push('/projects/new')
    }, [history])
    const handleClickModify = item => () => {
        history.push(`/projects/${item.id}`)
    }
    const handleChangePage = (evt, page) => {
        const q = {}
        q.page = page + 1
        if (searchOpts.customerId) {
            q.customerId = searchOpts.customerId
        }
        if (searchOpts.keyword) {
            q.keyword = searchOpts.keyword
        }
        history.push(`/?${queryString.stringify(q)}`)
    }
    const handleClickCustomerName = item => async () => {
        console.log({ item })
        try {
            const { ok, item: retItem } = await api.getCustomerByName(item.customerName)
            console.log({ok, retItem})
            console.log({ ok, retItem })
            if (ok) {
                history.push(`/?customerId=${retItem.id}`)
            }
        } finally {
            return false
        }
    }
    const handleClickDelete = item => async () => {
        if (!window.confirm('削除してもよろしいですか？')) {
            return false
        }
        const { ok } = await api.deleteProject(item.id)
        if (ok) {
            await fetchAsync()
        }
    }
    const [seikyuProjects, setSeikyuProjects] = useState([])
    const handleClickAddSeikyu = proj => () => {
        console.log({ proj })
        setSeikyuProjects(state => {
            const f = state.find(p => p.id === proj.id)
            console.log({ f })
            if (f != null) {
                return state
            }
            return [...state, proj]
        })
    }
    const handleClickDeleteSeikyuListItem = item => () => {
        setSeikyuProjects(state => state.filter(p => p.id !== item.id))
    }
    const handleSubmitSeikyu = async (seikyu) => {
        console.log("seikyu", seikyu)
        const { ok, item } = await api.createSeikyu(seikyu)
        if (ok) {
            const downloadHost = process.env.REACT_APP_DOWNLOAD_HOST || ""
            window.open(`${downloadHost}/api/seikyu/${item.id}/pdf/seikyu`)
        }
    }
    const isInSeikyu = proj => seikyuProjects.find(p => p.id === proj.id) != null
    const classes = useStyles()
    if (!fetched) {
        return <></>
    }
    return (
        <Content>
            <div className={classes.root}>
                <Button
                    className={classes.addButton}
                    variant="contained"
                    color="secondary"
                    onClick={handleClickAdd}>
                    <AddIcon />新規案件
                </Button>

                <TableContainer component={Paper}>
                    <form onSubmit={evt => {
                        evt.preventDefault()
                        history.push(`/?keyword=${keyword}`)
                    }}>
                        <TextField value={keyword} onChange={evt => setKeyword(evt.target.value)} />
                        <Button type="submit">検索</Button>
                    </form>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TablePagination
                                    page={searchOpts.page - 1}
                                    count={total}
                                    onChangePage={handleChangePage}
                                    rowsPerPage={PER_PAGE}
                                    rowsPerPageOptions={[PER_PAGE]}
                                />
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">No.</TableCell>
                                <TableCell align="left">名前</TableCell>
                                <TableCell align="left">案件名</TableCell>
                                <TableCell align="left">住所</TableCell>
                                <TableCell align="left">登録日時</TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map(item => (
                                <TableRow key={`id:${item.id}`}>
                                    <TableCell align="left"><Link href="#" onClick={evt => {evt.preventDefault(); handleClickModify(item)()}}>{item.id}</Link></TableCell>
                                    <TableCell align="left"><Link href="#" onClick={evt => {evt.preventDefault(); handleClickCustomerName(item)()}}>{item.customerName}</Link>{print(item.customerNameSufix)}</TableCell>
                                    <TableCell align="left"><Link to={`/projects/${item.id}`}>{item.projectName}</Link></TableCell>
                                    <TableCell align="left">{item.address}</TableCell>
                                    <TableCell align="left">{format(parseJSON(item.createdAt), "yyyy/MM/dd HH:mm")}</TableCell>
                                    <TableCell align="left">
                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={handleClickModify(item)}
                                        // disabled={!item.isVisible}
                                        >詳細</Button>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={handleClickAddSeikyu(item)}
                                            // disabled={!item.isVisible || isInSeikyu(item)}
                                            disabled={isInSeikyu(item)}
                                        ><AddIcon />請求</Button>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={handleClickDelete(item)}
                                            // disabled={!item.isVisible || isInSeikyu(item)}
                                            disabled={isInSeikyu(item)}
                                        ><DeleteIcon />削除</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    page={searchOpts.page - 1}
                                    count={total}
                                    onChangePage={handleChangePage}
                                    rowsPerPage={PER_PAGE}
                                    rowsPerPageOptions={[PER_PAGE]}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                {seikyuProjects.length > 0 && (
                    <Paper className={classes.seikyu}>
                        <List className={classes.sekyuList}>
                            {seikyuProjects.map(item => (
                                <ListItem key={item.id}>
                                    <ListItemText>
                                        {item.customerName}{print(item.customerNameSufix)} {item.address}
                                    </ListItemText>
                                    <ListItemSecondaryAction >
                                        <IconButton onClick={handleClickDeleteSeikyuListItem(item)}>
                                            <Delete />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                        <SeikyuForm projects={seikyuProjects} onSubmit={handleSubmitSeikyu} />
                    </Paper>
                )}
            </div>
        </Content>
    )
}

export default Project

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
    seikyu: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    addButton: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    seikyuButton: {
        marginTop: theme.spacing(2),
    },
    sekyuList: {
        width: 800,
    },
}))

const useSearchOpts = () => {
    const { search } = useLocation()
    const queryParams = queryString.parse(search)
    const { page = "1", customerId = "", keyword = "" } = queryParams
    const ret = useMemo(() => {
        const opts = {}
        opts.page = page ? page : "1"
        if (customerId) {
            opts.customerId = customerId
        }
        opts.perPage = PER_PAGE
        if (keyword !== "") {
            opts.keyword = keyword
        }
        return opts
    }, [customerId, keyword, page])
    return ret
}