import { Button, InputAdornment, makeStyles, MenuItem, TextField, Typography } from "@material-ui/core"
import { Save as SaveIcon } from '@material-ui/icons'
import { useRef, useState } from "react"
import SelectableTextField from "./SelectableTextField"

const CustomerForm = ({ initialValues, onSubmit, onCancel }) => {
    const refForm = useRef()
    const handleSubmit = evt => {
        try {
            evt.preventDefault()
            const form = refForm.current
            const item = [
                "name",
                "kana",
                "customerNameSufix",
                "customerTantou",
                "seikyuZipCode",
                "seikyuAddress",
                "kouza",
            ].reduce((p, c) => ({
                ...p,
                [c]: form[c].value,
            }), {})
            onSubmit(item)
            return false
        } catch (error) {
            console.log({ error })
        } finally {
            return false
        }
    }
    const handleCancel = onCancel
    const classes = useStyles()
    return (
        <form ref={refForm} onSubmit={handleSubmit} noValidate autoComplete="off">
            <Typography className={classes.h6} variant="h6">顧客情報</Typography>
            <div className={classes.formRow}>
                <TextField variant="outlined" name="name" label="顧客名" defaultValue={initialValues.name} />
                <TextField variant="outlined" name="kana" label="よみ（カナ）" defaultValue={initialValues.kana} />
                <TextField variant="outlined" select name="customerNameSufix" defaultValue={initialValues.customerNameSufix}>
                    <MenuItem value="onchu">御中</MenuItem>
                    <MenuItem value="sama">様</MenuItem>
                </TextField>
            </div>
            <div className={classes.formRow}>
                <TextField
                    variant="outlined"
                    name="customerTantou"
                    label="顧客担当名"
                    defaultValue={initialValues.customerTantou}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">様</InputAdornment>
                        )
                    }}
                />
            </div>
            <div className={classes.formRow}>
                <TextField variant="outlined" name="seikyuZipCode" label="請求先郵便番号(ハイフンなし)" defaultValue={initialValues.seikyuZipCode} inputProps={{ maxlength: 7 }} />
            </div>
            <div className={classes.formRow}>
                <TextField variant="outlined" name="seikyuAddress" label="請求先住所" defaultValue={initialValues.seikyuAddress} multiline rows={2} rowsMax={4} />
            </div>
            <div className={classes.formRow}>
                <SelectableTextField
                    className={classes.shiharaiJouken}
                    variant="outlined"
                    name="kouza"
                    label="振込み先口座"
                    multiline
                    defaultValue={initialValues.kouza}
                    menuValues={kouzaList}
                />
            </div>
            <div className={classes.formRow}>
                <Button type="submit" size="large" variant="contained" color="secondary" ><SaveIcon />保存</Button>
                <Button size="large" variant="text" color="default" onClick={handleCancel}>キャンセル</Button>
            </div>
        </form>
    )
}

export default CustomerForm

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
    },
    formRow: {
        padding: theme.spacing(1),
    },
    h6: {
        margin: theme.spacing(1),
    },
    select: {
        width: 200,
    },
    address: {
        width: 400,
    },
    biko: {
        width: 400,
    },
    shiharaiJouken: {
        width: 300,
    },
    map: {
        [theme.breakpoints.up('md')]: {
            width: 400,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: 400,
        },
        height: 300,
    },
}))


const kouzaList = [
    `静清信用金庫 片羽支店
普通 0231969　株式会社吉祥`,
    `静岡銀行 沓谷支店
普通 0928247　株式会社吉祥`,
]