import { useSession } from "./SessionProvider"
import SignIn from "./SignIn"

const Auth = props => {
    const { children } = props
    const { session, isLoading } = useSession()
    if (isLoading) {
        return <></>
    }
    if (!session) {
        return <SignIn />
    }
    return children
}

export default Auth