import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useBackUrl, useTitle } from "./navigation"
import * as api from './api'
import Content from "./Content"
import { Box, Button, Hidden, IconButton, makeStyles, Paper, Typography } from "@material-ui/core"
import { Edit as EditIcon } from '@material-ui/icons'
import { print } from './print'
import ProjectEdit from './ProjectEdit'
import MitumoriEdit from "./MitumoriEdit"
import { Fragment } from "react"
import GoogleMap from "./GoogleMap"
import { useSession } from "./SessionProvider"

const ProjectDetail = () => {
    useTitle('案件詳細')
    useBackUrl('/')
    const { session } = useSession()
    console.log({ session })
    const { id } = useParams()
    const [editMode, setEditMode] = useState()
    const [loading, setLoading] = useState(true)
    const [item, setItem] = useState({})
    const fetchProjectAsync = useCallback(async () => {
        try {
            const { ok, item } = await api.getProject(id)
            if (!ok) {
                return { ok: false }
            }
            setItem(item)
            return { ok: true }
        } catch (error) {
            return { ok: false, error }
        }
    }, [id])
    useEffect(() => {
        setLoading(true)
        fetchProjectAsync().then(() => setLoading(false))
    }, [fetchProjectAsync])

    const handleClickEditProject = useCallback(() => {
        setEditMode('project')
    }, [])

    const handleClickEditMitumori = useCallback(() => {
        setEditMode('mitumori')
    }, [])

    const handleCancelEdit = useCallback(() => {
        setEditMode(null)
    }, [])

    const handleSaveProject = useCallback(async itemValue => {
        try {
            console.log({ itemValue, item })
            const { ok, item: updatedItem } = await api.updateProject(item.id, itemValue)
            await fetchProjectAsync()
            console.log({ ok, updatedItem })
        } finally {
            setEditMode(null)
        }
    }, [fetchProjectAsync, item])

    const handleSaveMitumori = useCallback(async ({ items, nebiki }) => {
        try {
            const { ok } = await api.saveMitumori(item.id, { items, nebiki })
            if (ok) {
                await fetchProjectAsync()
            }
        } finally {
            setEditMode(null)
        }
    }, [fetchProjectAsync, item.id])

    const groupedMitumoriItems = useMemo(() => {
        if (!item) {
            return []
        }
        if (!item.mitumoriItems) {
            console.log({ item })
            return []
        }
        const obj = item.mitumoriItems.filter(item => item.quantity > 0).reduce((p, c) => {
            return {
                ...p,
                [c.itemGroup]: [...p[c.itemGroup], c]
            }
        }, {
            "kasetu_koji": [],
            "kaitai_koji": [],
            "haiki_shobun": [],
            "gaiko_tekkyo": [],
            "shyokeihi": [],
            "hikazei": [],
        })
        return Object.keys(obj).map(k => ({
            group: k,
            items: obj[k],
        })).filter(item => item.items.length > 0)
    }, [item])

    const classes = useStyles()

    const renderProjectDetail = useCallback(() => {
        return (
            <>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>工事件名</Typography>
                    <span>{item.projectName}</span>
                </div>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>顧客名</Typography>
                    <span>{item.customerName}{print(item.customerNameSufix)}</span>
                </div>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>顧客担当</Typography>
                    {item.customerTantou && (
                        <span>{item.customerTantou}様</span>
                    )}
                </div>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>請求先住所</Typography>
                    <span>
                        {item.seikyuZipCode && (
                            <>
                                {renderZipCode(item.seikyuZipCode)}
                                <br />
                            </>
                        )}
                        {(item.seikyuAddress || "").split("\n").map((v, i) => (
                            <Fragment key={`${i}`}>{v}<br /></Fragment>
                        ))}
                    </span>
                </div>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>住所</Typography>
                    <span>{item.address}</span>
                </div>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>階層</Typography>
                    <span>{item.kaisou}</span>
                </div>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>種別</Typography>
                    <span>{item.syubetu}</span>
                </div>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>前面道路サイズ</Typography>
                    <span>{item.zenmenDouroSize}</span>
                </div>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>隣家との距離</Typography>
                    <span>{item.rinkaKyori}</span>
                </div>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>架け面積</Typography>
                    <span>{item.nobeyukaMenseki}</span>
                </div>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>支払期限</Typography>
                    <span>{item.kigen}</span>
                </div>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>支払条件</Typography>
                    <span>{item.shiharaiJouken}</span>
                </div>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>担当者</Typography>
                    <span>{item.tantou}</span>
                </div>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>請求書日付</Typography>
                    <span>{item.seikyuHizuke}</span>
                </div>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>振り込み口座</Typography>
                    <div>
                        {(item.kouza || '').split('\n').map((v, i) => (
                            <Fragment key={`${i}`}>
                                {v}
                                <br />
                            </Fragment>
                        ))}
                    </div>
                </div>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>備考</Typography>
                    <div>
                        {(item.biko || '').split('\n').map((v, i) => <p key={i}>{v}</p>)}
                    </div>
                </div>
            </>
        )
    }, [classes.itemName, classes.itemRow, item.address, item.biko, item.customerName, item.customerNameSufix, item.customerTantou, item.kaisou, item.kigen, item.kouza, item.nobeyukaMenseki, item.projectName, item.rinkaKyori, item.seikyuAddress, item.seikyuHizuke, item.seikyuZipCode, item.shiharaiJouken, item.syubetu, item.tantou, item.zenmenDouroSize])

    const downloadHost = process.env.REACT_APP_DOWNLOAD_HOST || ""
    console.log({ downloadHost })

    const handleClickDownloadMitumori = () => {
        window.open(`${downloadHost}/api/projects/${item.id}/pdf/mitumori`, "_blank")
    }

    const handleClickDownloadSeikyu = () => {
        window.open(`${downloadHost}/api/projects/${item.id}/pdf/seikyu`, "_blank")
    }

    const handleClickDownloadHacchu = () => {
        window.open(`${downloadHost}/api/projects/${item.id}/pdf/hacchu`, "_blank")
    }

    const renderMitumoriDownloadButton = () => {
        // const disabled = !session.permissions.includes("downloadMitumori")
        const disabled = !item.isVisible
        return (
            <Button
                className={classes.mitumoriButton}
                variant="contained"
                color="primary"
                onClick={handleClickDownloadMitumori}
                disabled={disabled}>見積書ダウンロード</Button>
        )
    }

    const renderSeikyuDownloadButton = () => {
        // const disabled = !session.permissions.includes("downloadMeisai")
        const disabled = !item.isVisible
        return (
            <Button className={classes.mitumoriButton} variant="contained" color="primary" onClick={handleClickDownloadSeikyu} disabled={disabled}> 請求書ダウンロード</Button>
        )
    }

    const renderHacchuDownloadButton = () => {
        // const disabled = !session.permissions.includes("downloadHacchu")
        const disabled = !item.isVisible
        return (
            <Button className={classes.mitumoriButton} variant="contained" color="primary" onClick={handleClickDownloadHacchu} disabled={disabled}>発注書ダウンロード</Button>
        )
    }

    const renderMitumori = () => {
        if (editMode === 'mitumori') {
            return (
                <>
                    <div className={classes.h6row} >
                        <Typography variant="h6">
                            見積もり
                        </Typography>
                    </div>
                    <MitumoriEdit initialItems={item.mitumoriItems} initialNebiki={item.nebiki} onSubmit={handleSaveMitumori} onCancel={handleCancelEdit} />
                </>
            )
        }
        return (
            <>
                <div className={classes.h6row} >
                    <Typography variant="h6">
                        見積もり
                    </Typography>
                    <IconButton color="primary" onClick={handleClickEditMitumori}>
                        <EditIcon />
                    </IconButton>
                </div>
                {groupedMitumoriItems.length === 0 && (
                    <Box>
                        <Typography>見積情報はまだありません</Typography>
                    </Box>
                )}
                {groupedMitumoriItems.length > 0 && (

                    <>
                        {groupedMitumoriItems.map(grp => (
                            <Fragment key={grp.group}>
                                <Typography variant="h6">{print(grp.group)}</Typography>
                                {grp.items.map(item => (
                                    <div className={classes.mitumoriRow} key={item.name}>
                                        <div className={classes.itemName}>{item.displayName}</div>
                                        <div>{item.quantity}</div>
                                        <div>{print(item.itemUnit)}</div>
                                        <div>×</div>
                                        <div>{item.price}円</div>
                                    </div>
                                ))}
                            </Fragment>
                        ))}
                        {item.nebiki > 0 && (
                            <>
                                <Typography variant="h6">値引き</Typography>
                                <div className={classes.mitumoriRow} key="nebiki">
                                    <div className={classes.itemName}>値引き</div>
                                    <div>-{item.nebiki}円</div>
                                </div>
                            </>
                        )}
                        <div className={classes.mitumoriRow}>
                            {renderMitumoriDownloadButton()}
                            {renderSeikyuDownloadButton()}
                            {renderHacchuDownloadButton()}
                            {/*
                                    <Button className={classes.mitumoriButton} variant="contained" color="primary" onClick={handleClickDownloadMitumori}>見積書ダウンロード</Button>
                                    <Button className={classes.mitumoriButton} variant="contained" color="primary" onClick={handleClickDownloadSeikyu}>請求書ダウンロード</Button>
                                    <Button className={classes.mitumoriButton} variant="contained" color="primary" onClick={handleClickDownloadHacchu}>発注書ダウンロード</Button>
                                    */}
                        </div>
                    </>
                )
                }
            </>
        )
    }
    if (loading) {
        return <></>
    }

    return (
        <Content>
            <Paper className={classes.paper}>

                {editMode !== "project" && (
                    <>
                        <div className={classes.h6row} >
                            <Typography variant="h6">案件情報</Typography>
                            <IconButton color="primary" onClick={handleClickEditProject}>
                                <EditIcon />
                            </IconButton>
                        </div>
                        <Hidden smDown>
                            <div className={classes.detail2column}>
                                <div className={classes.detail2columnLeft}>
                                    {renderProjectDetail()}
                                </div>
                                <div className={classes.detail2columnRight}>
                                    <GoogleMap className={classes.map} map={item.map}></GoogleMap>
                                </div>
                            </div>
                        </Hidden>
                        <Hidden mdUp>
                            <GoogleMap className={classes.map} map={item.map}></GoogleMap>
                            {renderProjectDetail()}
                        </Hidden>
                    </>
                )}
                {editMode === 'project' && (
                    <ProjectEdit initialValues={item} onSubmit={handleSaveProject} onCancel={handleCancelEdit} />
                )}
            </Paper>
            {item.isVisible && (
                <Paper className={classes.paper}>
                    {renderMitumori()}
                </Paper>
            )}
        </Content>
    )
}

export default ProjectDetail


const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    detail2column: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    detail2columnLeft: {
        flexGrow: 1,
    },
    detail2columnRight: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    h6row: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    itemRow: {
        display: 'flex',
        marginBottom: theme.spacing(2),
    },
    itemName: {
        flexBasis: 150,
    },
    mitumoriRow: {
        display: 'flex',
        paddingBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    mitumoriButton: {
        marginRight: theme.spacing(1),
    },
    map: {
        [theme.breakpoints.up('md')]: {
            width: 400,
        },
        height: 300,
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: 400,
        },
    },
}))

const renderZipCode = s => {
    if (!s) {
        return ''
    }
    return `〒${s.slice(0, 3)}‐${s.slice(3, 7)}`
}