import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'

const ThemeProvider = ({ children }) => {
    return (
        <MuiThemeProvider theme={theme}>
            {children}
        </MuiThemeProvider>
    )
}

export default ThemeProvider

const theme = createMuiTheme({
    palette: {
        primary: {
            /*
            light: '#ffffff',
            main: '#dbd0e6',
            dark: '#a99fb4',
            contrastText: '#000000',
            */
            light: '#ffffff',
            main: '#6846A5',
            dark: '#a99fb4',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#ff5131',
            main: '#d50000',
            dark: '#9b0000',
            contrastText: '#ffffff',
        },
    },
    typography: {
        fontFamily: '"Noto Sans JP", "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
    },
    drawer: {
        width: 200,
    },
})
