import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useBackUrl, useTitle } from "./navigation"
import * as api from './api'
import Content from "./Content"
import { Box, Button, Hidden, IconButton, makeStyles, Paper, Typography } from "@material-ui/core"
import { Edit as EditIcon } from '@material-ui/icons'
import { print } from './print'
import CustomerEdit from './CustomerEdit'
import MitumoriEdit from "./MitumoriEdit"
import { Fragment } from "react"
import GoogleMap from "./GoogleMap"
import { useSession } from "./SessionProvider"

const CustomerDetail = () => {
    useTitle('顧客詳細')
    useBackUrl('/')
    const { session } = useSession()
    console.log({ session })
    const { id } = useParams()
    const [editMode, setEditMode] = useState()
    const [loading, setLoading] = useState(true)
    const [item, setItem] = useState({})
    const fetchCustomerAsync = useCallback(async () => {
        try {
            const { ok, item } = await api.getCustomerById(id)
            if (!ok) {
                return { ok: false }
            }
            setItem(item)
            return { ok: true }
        } catch (error) {
            return { ok: false, error }
        }
    }, [id])
    useEffect(() => {
        setLoading(true)
        fetchCustomerAsync().then(() => setLoading(false))
    }, [fetchCustomerAsync])

    const handleClickEdit = useCallback(() => {
        setEditMode('customer')
    }, [])

    const handleCancelEdit = useCallback(() => {
        setEditMode(null)
    }, [])

    const handleSave = useCallback(async itemValue => {
        try {
            console.log({ itemValue, item })
            const { ok, item: updatedItem } = await api.updateCustomer(item.id, itemValue)
            await fetchCustomerAsync()
            console.log({ ok, updatedItem })
        } finally {
            setEditMode(null)
        }
    }, [fetchCustomerAsync, item])

    const classes = useStyles()

    const renderDetail = useCallback(() => {
        return (
            <>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>顧客名</Typography>
                    <span>{item.name}{print(item.customerNameSufix)}</span>
                </div>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>よみ（カナ）</Typography>
                    <span>{item.kana}</span>
                </div>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>顧客担当</Typography>
                    {item.customerTantou && (
                        <span>{item.customerTantou}様</span>
                    )}
                </div>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>請求先住所</Typography>
                    <span>
                        {item.seikyuZipCode && (
                            <>
                                {renderZipCode(item.seikyuZipCode)}
                                <br />
                            </>
                        )}
                        {(item.seikyuAddress || "").split("\n").map((v, i) => (
                            <Fragment key={`${i}`}>{v}<br /></Fragment>
                        ))}
                    </span>
                </div>
                <div className={classes.itemRow}>
                    <Typography className={classes.itemName}>振り込み口座</Typography>
                    <div>
                        {(item.kouza || '').split('\n').map((v, i) => (
                            <Fragment key={`${i}`}>
                                {v}
                                <br />
                            </Fragment>
                        ))}
                    </div>
                </div>
            </>
        )
    }, [classes.itemName, classes.itemRow, item.customerNameSufix, item.customerTantou, item.kouza, item.name, item.seikyuAddress, item.seikyuZipCode])

    if (loading) {
        return <></>
    }

    return (
        <Content>
            <Paper className={classes.paper}>

                {editMode !== "customer" && (
                    <>
                        <div className={classes.h6row} >
                            <Typography variant="h6">顧客情報</Typography>
                            <IconButton color="primary" onClick={handleClickEdit}>
                                <EditIcon />
                            </IconButton>
                        </div>
                        <Hidden smDown>
                            <div className={classes.detail2column}>
                                <div className={classes.detail2columnLeft}>
                                    {renderDetail()}
                                </div>
                            </div>
                        </Hidden>
                        <Hidden mdUp>
                            {renderDetail()}
                        </Hidden>
                    </>
                )}
                {editMode === 'customer' && (
                    <CustomerEdit initialValues={item} onSubmit={handleSave} onCancel={handleCancelEdit} />
                )}
            </Paper>
        </Content>
    )
}

export default CustomerDetail


const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    detail2column: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    detail2columnLeft: {
        flexGrow: 1,
    },
    detail2columnRight: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    h6row: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    itemRow: {
        display: 'flex',
        marginBottom: theme.spacing(2),
    },
    itemName: {
        flexBasis: 150,
    },
    mitumoriRow: {
        display: 'flex',
        paddingBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    mitumoriButton: {
        marginRight: theme.spacing(1),
    },
    map: {
        [theme.breakpoints.up('md')]: {
            width: 400,
        },
        height: 300,
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: 400,
        },
    },
}))

const renderZipCode = s => {
    if (!s) {
        return ''
    }
    return `〒${s.slice(0, 3)}‐${s.slice(3, 7)}`
}