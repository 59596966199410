import { useTitle } from "./navigation"
import ProjectEdit from "./ProjectEdit"
import * as api from './api'
import { useCallback, useEffect, useState } from "react"
import Content from "./Content"
import { useHistory } from "react-router-dom"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

const ProjectCreate = () => {
    useTitle('新規案件')
    const params = useParams()
    const history = useHistory()
    const handleSubmit = useCallback(async item => {
        const { ok, item: createdItem } = await api.createProject(item)
        console.log({ ok, item, createdItem })
        history.replace(`/projects/${createdItem.id}`)
    }, [history])
    const handleCancel = useCallback(() => {
        history.push(`/`)
    }, [history])
    const [iniValues, setIniValues] = useState(initialValues)

    const fetchCustomerAsync = useCallback(async (customerId) => {
        const {ok, item: customer} = await api.getCustomerById(customerId)
        console.log("customer", customer)
        if (!ok) {
            return
        }
        setIniValues(values => ({
            ...values,
            customerName: customer.name,
            customerNameSufix: customer.customerNameSufix,
            customerTantou: customer.customerTantou,
            seikyuZipCode: customer.seikyuZipCode,
            seikyuAddress: customer.seikyuAddress,
            kouza: customer.kouza,
        }))
        history.replace(`/projects/new`)
    }, [history])

    useEffect(() => {
        if (params.customerId != null) {
            fetchCustomerAsync(params.customerId)
        }
    }, [fetchCustomerAsync, params.customerId])

    if (params.customerId != null) {
        return <></>
    }

    return (
        <Content>
            <ProjectEdit
                initialValues={iniValues}
                onSubmit={handleSubmit} 
                onCancel={handleCancel} />
        </Content>
    )
}

export default ProjectCreate

const initialValues = {
    customerName: "",
    customerNameSufix: "onchu",
    customerTantou: "",
    address: "",
    kouzou: "",
    kaisou: "",
    syubetu: "",
    zenmenDouroSize: "",
    rinkaKyori: "",
    nobeyukaMenseki: "",
    nebiki: "",
    kigen: "",
    shiharaiJouken: '',
    tantou: '',
    seikyuZipCode: '',
    seikyuAddress: '',
    kouza: '',
    biko: "",
    seikyuHizuke: '',
    map: null,
}
