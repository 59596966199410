import { Divider, Drawer as MuiDrawer, Hidden, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core'
import { useCallback } from 'react'
import { useNavigation } from './navigation'
import { useHistory } from 'react-router-dom'
import { List as IcList, Person as IcPerson, ExitToApp } from '@material-ui/icons'
import logo from './logoh.png'
import { useSession } from './SessionProvider'

const TOP = "TOP"
const CUSTOMER = "CUSTOMER"
const LOGOUT = "LOGOUT"

const Drawer = () => {
    const { drawerOpen, closeDrawer } = useNavigation()
    const {signOutAsync} = useSession()
    const history = useHistory()
    const classes = useStyles()
    const handleClose = useCallback(() => closeDrawer(), [closeDrawer])
    const handleClickListItem = useCallback(item => () => {
        switch (item) {
            case TOP:
                history.push('/')
                break
            case CUSTOMER:
                history.push('/customers')
                break
            case LOGOUT:
                signOutAsync()
                break
            default:
                break
        }
        closeDrawer()
    }, [closeDrawer, history, signOutAsync])
    const renderList = useCallback(() => {
        return (
            <div className={classes.list}>
                <img className={classes.list} src={logo} alt="logo" />
                <Divider />
                <List className={classes.list} component="nav">
                    <ListItem button onClick={handleClickListItem(TOP)}>
                        <ListItemIcon ><IcList /></ListItemIcon>
                        <ListItemText
                            primary="案件一覧"
                        />
                    </ListItem>
                    <ListItem button onClick={handleClickListItem(CUSTOMER)}>
                        <ListItemIcon ><IcPerson /></ListItemIcon>
                        <ListItemText
                            primary="顧客一覧"
                        />
                    </ListItem>
                    <ListItem button onClick={handleClickListItem(LOGOUT)}>
                        <ListItemIcon ><ExitToApp /></ListItemIcon>
                        <ListItemText
                            primary="ログアウト"
                        />
                    </ListItem>
                </List>
            </div>
        )
    }, [classes.list, handleClickListItem])
    return (
        <>
            <Hidden mdUp>
                <MuiDrawer
                    anchor="left"
                    open={drawerOpen}
                    onClose={handleClose}
                >
                    {renderList()}
                </MuiDrawer>
            </Hidden>
            <Hidden smDown>
                <MuiDrawer
                    anchor="left"
                    open={true}
                    onClose={handleClose}
                    variant="persistent"
                >
                    {renderList()}
                </MuiDrawer>
            </Hidden>
        </>
    )
}

export default Drawer

const useStyles = makeStyles(() => ({
    list: {
        width: 250,
    },
}))