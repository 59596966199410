import { createContext, forwardRef, useCallback, useContext, useEffect, useRef, useState } from "react"
import { Loader } from "@googlemaps/js-api-loader"

const loader = new Loader({
    apiKey: "AIzaSyAXJaB5qdLXpFk_F4WymTpkadANE6FJ8Qc",
    version: "weekly",
});

const GoogleMap = forwardRef(({ component = 'div', address: providedAddress = '', map: providedMap, onChangeCenter, onChangeZoom, onMapLoad, editable, ...props }, ref) => {
    console.log('googleMap')
    const refDom = useRef()
    const refMap = useRef()
    const refMarker = useRef()
    const refAddress = useRef()
    const Component = component ? component : 'div'
    const { googleMapLoaded } = useGoogleMap()
    const [initialAddress] = useState(providedAddress ? providedAddress : '静岡県静岡市')
    const [initialMap, setInitialMap] = useState(providedMap ? providedMap : null)
    const handleChangeMap = useCallback(() => {
        const center = refMap.current.getCenter()
        console.log('center changed', { center, lat: center.lat(), lng: center.lng(), zoom: refMap.current.getZoom() })
        if (ref) {
            ref.current = {
                lat: center.lat(),
                lng: center.lng(),
                zoom: refMap.current.getZoom(),
            }
        }
    }, [ref])
    const loadGeocodeAsync = useCallback(async () => {
        const geocoder = new window.google.maps.Geocoder()
        const { ok: geocoderOk, result: geocoderResult } = await new Promise(resolve => geocoder.geocode({ address: initialAddress }, (results, status) => {
            if (status !== window.google.maps.GeocoderStatus.OK) {
                return resolve({ ok: false })
            }
            return resolve({ ok: true, result: results[0] })
        }))
        console.log({ geocoderOk, geocoderResult })
        if (!geocoderOk) {
            return
        }
        setInitialMap({
            lat: geocoderResult.geometry.location.lat(),
            lng: geocoderResult.geometry.location.lng(),
            zoom: 12,
        })
    }, [initialAddress])
    const loadAsync = useCallback(async () => {
        if (!initialMap) {
            loadGeocodeAsync()
            return
        }
        console.log({ initialMap })
        refMap.current = new window.google.maps.Map(refDom.current, {
            center: { lat: initialMap.lat, lng: initialMap.lng },
            zoom: initialMap.zoom,
        });
        refMarker.current = new window.google.maps.Marker({ position: refMap.current.getCenter(), map: refMap.current, })
        if (ref) {
            ref.current = {
                ...initialMap,
            }
        }
        const zoomChangedListener = refMap.current.addListener("zoom_changed", () => handleChangeMap())

        let clickListener
        if (editable) {
            clickListener = refMap.current.addListener('click', evt => {
                refMap.current.panTo(evt.latLng)
                refMarker.current.setPosition(evt.latLng)
                if (ref) {
                    ref.current = {
                        zoom: refMap.current.getZoom(),
                        lat: evt.latLng.lat(),
                        lng: evt.latLng.lng(),
                    }
                }
            })
        }
        if (onMapLoad) {
            onMapLoad()
        }
        return () => {
            window.google.maps.event.removeListener(zoomChangedListener)
            if (clickListener) {
                window.google.maps.event.removeListener(clickListener)
            }
        }
    }, [editable, handleChangeMap, initialMap, loadGeocodeAsync, onMapLoad, ref])
    const setCenterAddressAsync = useCallback(async address => {
        const geocoder = new window.google.maps.Geocoder()
        const { ok: geocoderOk, result: geocoderResult } = await new Promise(resolve => geocoder.geocode({ address }, (results, status) => {
            if (status !== window.google.maps.GeocoderStatus.OK) {
                return resolve({ ok: false })
            }
            return resolve({ ok: true, result: results[0] })
        }))
        console.log({ geocoderOk, geocoderResult })
        if (!geocoderOk) {
            return
        }
        refMap.current.setCenter(geocoderResult.geometry.location)
        refMarker.current.setPosition(geocoderResult.geometry.location)
        if (ref) {
            ref.current = {
                lat: refMap.current.getCenter().lat(),
                lng: refMap.current.getCenter().lng(),
                zoom: refMap.current.getZoom(),
            }
        }
    }, [ref])
    useEffect(() => {
        if (!googleMapLoaded) {
            return
        }
        loadAsync()
    }, [googleMapLoaded, loadAsync])
    useEffect(() => {
        if (!refAddress.current) {
            refAddress.current = providedAddress
            return
        }
        refAddress.current = providedAddress
        const timer = setTimeout(() => {
            console.log(refAddress.current)
            setCenterAddressAsync(refAddress.current)
        }, 2000)
        return () => clearTimeout(timer)
    }, [providedAddress, setCenterAddressAsync])
    return (
        <Component {...props} ref={refDom}></Component>
    )
})

export default GoogleMap

const context = createContext()

export const GoogleMapProvider = ({ children }) => {
    const [googleMapLoaded, setGoogleMapLoaded] = useState(false)
    const loadAsync = async () => {
        if (window.google && window.google.map) {
            setGoogleMapLoaded(true)
            return
        }
        await loader.load()
        setGoogleMapLoaded(true)
    }
    useEffect(() => {
        loadAsync()
    }, [])
    const value = {
        googleMapLoaded,
    }
    return <context.Provider value={value}>{children}</context.Provider>
}

export const useGoogleMap = () => {
    return useContext(context)
}
