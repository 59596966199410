import { Button, InputAdornment, makeStyles, MenuItem, TextField, Typography } from "@material-ui/core"
import { Save as SaveIcon } from '@material-ui/icons'
import { useRef, useState } from "react"
import GoogleMap from "./GoogleMap"
import SelectableTextField from "./SelectableTextField"

const ProjectForm = ({ initialValues, onSubmit, onCancel }) => {
    const refForm = useRef()
    const refMap = useRef()
    const [mapLoaded, setMapLoaded] = useState(false)
    const handleMapLoad = () => {
        setMapLoaded(true)
    }
    const [address, setAddress] = useState(initialValues.address)
    const handleChangeAddress = evt => setAddress(evt.target.value)
    const handleSubmit = evt => {
        try {
            evt.preventDefault()
            const form = refForm.current
            const item = [
                "projectName",
                "customerName",
                "customerNameSufix",
                "customerTantou",
                "kaisou",
                "syubetu",
                "zenmenDouroSize",
                "rinkaKyori",
                "nobeyukaMenseki",
                "kikan",
                "kigen",
                "shiharaiJouken",
                "tantou",
                "seikyuZipCode",
                "seikyuAddress",
                "kouza",
                "biko",
                'seikyuHizuke',
            ].reduce((p, c) => ({
                ...p,
                [c]: form[c].value,
            }), {})
            item.address = address
            if (refMap.current) {
                item.map = {
                    lat: refMap.current.lat,
                    lng: refMap.current.lng,
                    zoom: refMap.current.zoom,
                }
            }
            console.log({ item })
            onSubmit(item)
            return false
        } catch (error) {
            console.log({ error })
        } finally {
            return false
        }
    }
    const handleCancel = onCancel
    const submitDisabled = !mapLoaded
    const classes = useStyles()
    return (
        <form ref={refForm} onSubmit={handleSubmit} noValidate autoComplete="off">
            <Typography className={classes.h6} variant="h6">案件情報</Typography>
            <div className={classes.formRow}>
                <TextField variant="outlined" name="projectName" label="件名" defaultValue={initialValues.projectName} />
            </div>
            <div className={classes.formRow}>
                <TextField variant="outlined" name="customerName" label="顧客名" defaultValue={initialValues.customerName} />
                <TextField variant="outlined" select name="customerNameSufix" defaultValue={initialValues.customerNameSufix}>
                    <MenuItem value="onchu">御中</MenuItem>
                    <MenuItem value="sama">様</MenuItem>
                </TextField>
            </div>
            <div className={classes.formRow}>
                <TextField
                    variant="outlined"
                    name="customerTantou"
                    label="顧客担当名"
                    defaultValue={initialValues.customerTantou}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">様</InputAdornment>
                        )
                    }}
                />
            </div>
            <div className={classes.formRow}>
                <TextField variant="outlined" name="seikyuZipCode" label="請求先郵便番号(ハイフンなし)" defaultValue={initialValues.seikyuZipCode} inputProps={{ maxlength: 7 }} />
            </div>
            <div className={classes.formRow}>
                <TextField variant="outlined" name="seikyuAddress" label="請求先住所" defaultValue={initialValues.seikyuAddress} multiline rows={2} rowsMax={4} />
            </div>
            <div className={classes.formRow}>
                <TextField className={classes.address} variant="outlined" name="address" label="住所" value={address} onChange={handleChangeAddress} />
            </div>
            <GoogleMap
                className={classes.map}
                address={address}
                map={initialValues.map}
                ref={refMap}
                onMapLoad={handleMapLoad}
                editable
            />
            <div className={classes.formRow}>
                <SelectableTextField
                    className={classes.select}
                    variant="outlined"
                    name="kaisou"
                    label="階層"
                    defaultValue={initialValues.kaisou}
                    menuValues={[
                        '1階建て',
                        '2階建て',
                        '3階建て',
                        '4階建て',
                        '5階建て',
                        '6階建て',
                        '7階建て',
                        '8階建て',
                        '9階建て',
                        '10階建て',
                    ]}
                />
            </div>
            <div className={classes.formRow}>
                <SelectableTextField
                    className={classes.select}
                    variant="outlined"
                    name="syubetu"
                    label="種別"
                    defaultValue={initialValues.syubetu}
                    menuValues={['専用住宅', '店舗', '商業施設', 'アパート', '工場', 'マンション', 'ビル']}
                />
            </div>
            <div className={classes.formRow}>
                <TextField className={classes.select} variant="outlined" name="zenmenDouroSize" label="前面道路サイズ" defaultValue={initialValues.zenmenDouroSize} />
            </div>
            <div className={classes.formRow}>
                <TextField className={classes.select} variant="outlined" name="rinkaKyori" label="隣家との距離" defaultValue={initialValues.rinkaKyori} />
            </div>
            <div className={classes.formRow}>
                <TextField
                    variant="outlined"
                    name="nobeyukaMenseki"
                    label="架け面積"
                    defaultValue={initialValues.nobeyukaMenseki}
                />
            </div>
            <div className={classes.formRow}>
                <TextField variant="outlined" name="kikan" label="工事期間" defaultValue={initialValues.kikan} />
            </div>
            <div className={classes.formRow}>
                <SelectableTextField
                    className={classes.shiharaiJouken}
                    variant="outlined"
                    name="shiharaiJouken"
                    label="支払条件"
                    defaultValue={initialValues.shiharaiJouken}
                    menuValues={[
                        '別途御協議',
                        '工事完工後　○日以内',
                        '着手前○% 完工後○%(○日以内)',
                        '完工月末締めから翌月末',
                        '完工月末締から○日',
                        '',
                    ]}
                />
            </div>
            <div className={classes.formRow}>
                <TextField variant="outlined" name="kigen" label="見積書有効期限" defaultValue={initialValues.kigen} />
            </div>
            <div className={classes.formRow}>
                <TextField variant="outlined" name="tantou" label="担当者" defaultValue={initialValues.tantou} />
            </div>
            <div className={classes.formRow}>
                <TextField variant="outlined" name="seikyuHizuke" label="請求書日付" defaultValue={initialValues.seikyuHizuke} />
            </div>
            <div className={classes.formRow}>
                <SelectableTextField
                    className={classes.shiharaiJouken}
                    variant="outlined"
                    name="kouza"
                    label="振込み先口座"
                    multiline
                    defaultValue={initialValues.kouza}
                    menuValues={kouzaList}
                />
            </div>
            <div className={classes.formRow}>
                <TextField
                    className={classes.biko}
                    variant="outlined"
                    name="biko"
                    label="備考"
                    multiline
                    rows={4}
                    rowsMax={4}
                    defaultValue={initialValues.biko}
                />
            </div>
            <div className={classes.formRow}>
                <Button type="submit" size="large" variant="contained" color="secondary" disabled={submitDisabled}><SaveIcon />保存</Button>
                <Button size="large" variant="text" color="default" onClick={handleCancel}>キャンセル</Button>
            </div>
        </form>
    )
}

export default ProjectForm

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
    },
    formRow: {
        padding: theme.spacing(1),
    },
    h6: {
        margin: theme.spacing(1),
    },
    select: {
        width: 200,
    },
    address: {
        width: 400,
    },
    biko: {
        width: 400,
    },
    shiharaiJouken: {
        width: 300,
    },
    map: {
        [theme.breakpoints.up('md')]: {
            width: 400,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: 400,
        },
        height: 300,
    },
}))


const kouzaList = [
    `静清信用金庫 片羽支店
普通 0231969　株式会社吉祥`,
    `静岡銀行 沓谷支店
普通 0928247　株式会社吉祥`,
]