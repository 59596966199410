import { forwardRef, useEffect, useState } from "react"

const MitumoriSum = forwardRef((props, ref) => {
    const [total, setTotal] = useState(props.initialTotal)
    useEffect(() => {
        ref.current = {
            setTotal,
        }
    }, [ref])
    return <>{total.toLocaleString()}</>
})

export default MitumoriSum
