import { Button, Link, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from "@material-ui/core"
import { useCallback, useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router"
import * as api from './api'
import Content from "./Content"
import { Add as AddIcon } from '@material-ui/icons'
import { format, parseJSON } from 'date-fns'
import { useBackUrl, useTitle } from "./navigation"

const PER_PAGE = 10

const queryString = require('query-string')

const Customer = () => {
    useTitle("顧客一覧")
    useBackUrl("/")
    const history = useHistory()
    const { search } = useLocation()
    const queryParams = queryString.parse(search)
    const { page = "1" } = queryParams
    const [result, setResult] = useState({ total: 0, items: [] })
    const { items, total } = result
    const [keyword, setKeyword] = useState(queryParams.keyword ?? "")
    const fetchAsync = useCallback(async () => {
        const opts = { page: queryParams.page ?? "1" }
        if (queryParams.keyword != null && queryParams.keyword !== "") {
            opts.keyword = queryParams.keyword
        }
        if (queryParams.sort != null && queryParams.sort !== "") {
            opts.sort = queryParams.sort
        }
        const { ok, items, total } = await api.searchCustomers(opts)
        if (!ok) {
            return
        }
        setResult({ items, total })
    }, [queryParams.keyword, queryParams.page, queryParams.sort])
    useEffect(() => {
        fetchAsync()
    }, [fetchAsync])
    const classes = useStyles()
    const handleClickAdd = useCallback(() => {
        history.push('/projects/new')
    }, [history])
    const handleChangePage = (evt, page) => {
        history.push(`/customers?page=${page + 1}`)
    }
    const handleClickCustomersProjects = customer => evt => {
        history.push(`/?customerId=${customer.id}`)
    }
    const handleClickNewProject = customer => evt => {
        history.push(`/projects/new/customer=${customer.id}`)
    }

    const handleClickDetail = customer => evt => {
        history.push(`/customers/${customer.id}`)
    }

    const updateQueryParams = (k, v) => {
        const qp = queryString.parse(search)
        qp[k] = v
        const s = queryString.stringify(qp)
        let url = `/customers`
        if (s !== "") {
            url = `${url}?${s}`
        }
        return url
    }
    const nextSort = (cur, s) => {
        switch (s) {
            case "kana":
                switch (cur) {
                    case "kana_u":
                        return "kana_l"
                    case "kana_l":
                    default:
                        return "kana_u"
                }
            case "updated":
                switch (cur) {
                    case "updated_u":
                        return "updated_l"
                    case "updated_l":
                    default:
                        return "updated_u"
                }
            default:
                return ""
        }
    }
    const getNextSortUrl = s  => {
        const cur = queryParams.sort ?? ""
        const sort = nextSort(cur, s)
        return updateQueryParams("sort", sort)
    }
    return (
        <Content>
            <div className={classes.root}>
                <Button
                    className={classes.addButton}
                    variant="contained"
                    color="secondary"
                    onClick={handleClickAdd}>
                    <AddIcon />新規顧客
                </Button>
                <TableContainer component={Paper}>
                    <form onSubmit={evt => {
                        evt.preventDefault()
                        history.push(`/customers?keyword=${keyword}`)
                    }}>
                        <TextField value={keyword} onChange={evt => setKeyword(evt.target.value)} />
                        <Button type="submit">検索</Button>
                    </form>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TablePagination
                                    page={page - 1}
                                    count={total}
                                    onChangePage={handleChangePage}
                                    rowsPerPage={PER_PAGE}
                                    rowsPerPageOptions={[PER_PAGE]}
                                />
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">名前</TableCell>
                                <TableCell align="left"><Link href={getNextSortUrl("kana")} onClick={evt => {evt.preventDefault(); history.push(getNextSortUrl("kana"))}}>カナ</Link></TableCell>
                                <TableCell align="left">登録件数</TableCell>
                                <TableCell align="left">登録日時</TableCell>
                                <TableCell align="left"><Link href={getNextSortUrl("updated")} onClick={evt => {evt.preventDefault(); history.push(getNextSortUrl("updated"))}}>更新日時</Link></TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map(item => (
                                <TableRow key={`id:${item.id}`}>
                                    <TableCell align="left"><Link href="#" onClick={handleClickDetail(item)}>{item.name}</Link></TableCell>
                                    <TableCell align="right">{item.kana}</TableCell>
                                    <TableCell align="right">{item.count}</TableCell>
                                    <TableCell align="left">{format(parseJSON(item.createdAt), "yyyy/MM/dd HH:mm")}</TableCell>
                                    <TableCell align="left">{format(parseJSON(item.updatedAt), "yyyy/MM/dd HH:mm")}</TableCell>
                                    <TableCell align="left">
                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={handleClickCustomersProjects(item)}
                                        >案件一覧</Button>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={handleClickNewProject(item)}
                                        >新規案件</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    page={page - 1}
                                    count={total}
                                    onChangePage={handleChangePage}
                                    rowsPerPage={PER_PAGE}
                                    rowsPerPageOptions={[PER_PAGE]}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </Content>
    )

}

export default Customer

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
    addButton: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
    }
}))