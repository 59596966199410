import { Button, Divider, Hidden, IconButton, InputAdornment, makeStyles, MenuItem, Paper, TextField, Typography } from "@material-ui/core"
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { print } from "./print"
import { Add as AddIcon, RemoveCircleOutline as RemoveIcon } from '@material-ui/icons'
import { Fragment } from "react"
import SelectableTextField from "./SelectableTextField"
import MitumoriSum from "./MitumoriSum"

const MitumoriEdit = ({ initialNebiki, initialItems, onSubmit, onCancel }) => {
    const refForm = useRef()
    const handleCancel = onCancel

    const [items, setItems] = useState(initialItems.map((item, i) => {
        if (!item.isCustom) {
            return item
        }
        return {
            ...item,
            name: `custom_${i}`,
        }
    }))
    const [initialTotal] = useState(initialItems.reduce((sum, item) => {
        if (item.quantity) {
            sum += Math.floor(parseFloatNum(item.quantity) * item.price)
        }
        return sum
    }, 0))
    const [initialItemsTotals] = useState(items.reduce((o, item) => {
        console.log({ o, item })
        if (item.quantity) {
            return {
                ...o,
                [item.name]: Math.floor(parseFloatNum(item.quantity) * item.price)
            }
        } else {
            return {
                ...o,
                [item.name]: 0,
            }
        }
    }, {}))
    console.log({ initialTotal, initialItemsTotals })
    const [customCount, setCustomCount] = useState(items.filter(item => item.isCustom).length)

    const handleAddItem = group => () => {
        setItems(items => ([
            ...items,
            {
                itemGroup: group,
                name: `custom_${customCount}`,
                displayName: "",
                itemUnit: "shiki",
                price: 0,
                quantity: 0,
                isCustom: true,
            }
        ]))
        setCustomCount(c => c + 1)
    }
    const groups = useMemo(() => ([
        "kasetu_koji",
//        "kaitai_koji",
//        "haiki_shobun",
//        "gaiko_tekkyo",
        "shyokeihi",
        "hikazei",
    ].map(grpName => ({
        group: grpName,
        items: items.filter(item => item.itemGroup === grpName),
    }))), [items])
    const handleRemoveItem = item => () => {
        console.log("removeItem")
        setItems(items => items.filter(v => v.name !== item.name))
    }
    const handleSubmit = evt => {
        try {
            evt.preventDefault()
            const form = refForm.current
            console.log({ form })
            const itemValues = []
            for (let i = 0; i < items.length; i++) {
                const item = items[i]
                if ((item.isCustom || item.nameEditable)) {
                    itemValues.push({
                        ...item,
                        displayName: form[`${item.name}_displayName`].value,
                        itemUnit: form[`${item.name}_itemUnit`].value,
                        quantity: form[`${item.name}_quantity`].value,
                        price: parseNum(form[`${item.name}_price`].value),
                        kikaku: form[`${item.name}_kikaku`].value,
                        bikou: form[`${item.name}_bikou`].value,
                    })
                } else {
                    itemValues.push({
                        ...item,
                        itemUnit: form[`${item.name}_itemUnit`].value,
                        quantity: form[`${item.name}_quantity`].value,
                        price: parseNum(form[`${item.name}_price`].value),
                        kikaku: form[`${item.name}_kikaku`].value,
                        bikou: form[`${item.name}_bikou`].value,
                    })
                }
            }
            const nebiki = parseNum(form.nebiki.value)
            console.log({ values: itemValues })
            onSubmit({ items: itemValues, nebiki })
        } finally {
            return false
        }
    }

    const refTotal = useRef()
    const recalcTotal = useCallback(() => {
        const form = refForm.current
        const nebiki = parseNum(form.nebiki.value)
        const total = items.reduce((sum, item) => {
            const q = parseFloatNum(form[`${item.name}_quantity`].value)
            const p = parseNum(form[`${item.name}_price`].value)
            sum += Math.floor(q * p)
            return sum
        }, 0) - nebiki
        refTotal.current.setTotal(total)
    }, [items])
    const refItemTotals = useRef({})
    const recalcItemTotals = useCallback(() => {
        console.log('recalcItemTotals', items)
        const form = refForm.current
        const itemTotals = items.reduce((o, item) => {
            const q = parseFloatNum(form[`${item.name}_quantity`].value)
            const p = parseNum(form[`${item.name}_price`].value)
            if (q > 0 && p > 0) {
                const sum = Math.floor(q * p)
                return {
                    ...o,
                    [item.name]: sum,
                }
            } else {
                return {
                    ...o,
                    [item.name]: 0,
                }
            }
        }, {})
        const ks = Object.keys(itemTotals)
        for (let i = 0; i < ks.length; i++) {
            const k = ks[i]
            console.log({ k, refItemTotals })
            if (refItemTotals.current[k]) {
                refItemTotals.current[k].setTotal(itemTotals[k])
            }
        }
    }, [items])
    const handleChange = useCallback(() => {
        recalcTotal()
        recalcItemTotals()
    }, [recalcItemTotals, recalcTotal])
    useEffect(() => {
        recalcTotal()
        recalcItemTotals()
    }, [recalcItemTotals, recalcTotal])
    const classes = useStyles()
    console.log({ groups })
    return (
        <>
            <form ref={refForm} onSubmit={handleSubmit} noValidate autoComplete="off">
                {groups.map(group => (
                    <Fragment key={group.group}>
                        <Divider />
                        <div className={classes.formRow}>
                            <Typography variant="h6" className={classes.groupName}>
                                {print(group.group)}
                            </Typography>
                        </div>
                        {group.items.map(item => (
                            <div className={classes.formRow} key={`item_${item.name}`}>
                                {(item.isCustom || item.nameEditable) && (
                                    <div className={classes.itemName} >
                                        <div className={classes.formRowColLine}>
                                            <TextField size="small" className={classes.itemNameField} variant="outlined" name={`${item.name}_displayName`} defaultValue={item.displayName} placeholder="項目名" />
                                        </div>
                                    </div>
                                )}
                                {!(item.isCustom || item.nameEditable) && (
                                    <div className={classes.itemName} >
                                        <div className={classes.formRowColLine}>
                                            {item.displayName}
                                        </div>
                                    </div>
                                )}
                                <div className={classes.formRowCol}>
                                    <div className={classes.formRowColLine}>
                                        <TextField
                                            type="number"
                                            size="small"
                                            className={classes.quantity}
                                            variant="outlined" name={`${item.name}_quantity`}
                                            defaultValue={item.quantity > 0 ? item.quantity : ''}
                                            onChange={handleChange}
                                        />
                                        <TextField size="small" className={classes.itemUnit} variant="outlined" name={`${item.name}_itemUnit`} defaultValue={item.itemUnit} select>
                                            <MenuItem value="nichi">日</MenuItem>
                                            <MenuItem value="ninku">人工</MenuItem>
                                            <MenuItem value="jikan">時間</MenuItem>
                                            <MenuItem value="shiki">式</MenuItem>
                                            <MenuItem value="m3">㎥</MenuItem>
                                            <MenuItem value="m2">㎡</MenuItem>
                                            <MenuItem value="dai">台</MenuItem>
                                            <MenuItem value="cm">cm</MenuItem>
                                            <MenuItem value="m">ｍ</MenuItem>
                                            <MenuItem value="kai">回</MenuItem>
                                            <MenuItem value="sou">槽</MenuItem>
                                            <MenuItem value="ko">個</MenuItem>
                                            <MenuItem value="hon">本</MenuItem>
                                            <MenuItem value="ki">基</MenuItem>
                                            <MenuItem value="mai">枚</MenuItem>
                                            <MenuItem value="kasho">箇所</MenuItem>
                                            <MenuItem value="kg">kg</MenuItem>
                                            <MenuItem value="t">t</MenuItem>
                                            <MenuItem value="mei">名</MenuItem>
                                        </TextField>
                                        <span>×</span>
                                        <TextField
                                            type="number"
                                            size="small"
                                            className={classes.price}
                                            variant="outlined"
                                            name={`${item.name}_price`}
                                            defaultValue={item.price > 0 ? item.price : ''}
                                            onChange={handleChange}
                                        />
                                        <span>円</span>
                                        <Hidden smDown>
                                            <ItemSum ref={refItemTotals} initialItemsTotals={initialItemsTotals} name={item.name} />
                                        </Hidden>
                                        {item.isCustom && (
                                            <IconButton color="primary" onClick={handleRemoveItem(item)}>
                                                <RemoveIcon />
                                            </IconButton>
                                        )}
                                    </div>
                                    <Hidden mdUp>
                                        <ItemSum ref={refItemTotals} initialItemsTotals={initialItemsTotals} name={item.name} />
                                    </Hidden>
                                    <div className={classes.formRowColLine}>
                                        {group.group === 'kaitai_koji' && (
                                            <SelectableTextField
                                                className={classes.kikaku}
                                                variant="outlined"
                                                size="small"
                                                name={`${item.name}_kikaku`}
                                                label="内容"
                                                defaultValue={item.kikaku}
                                                menuValues={['解体・運搬・処分', '撤去・運搬・処分', '運搬・処分']}
                                            />
                                        )}
                                        {group.group !== 'kaitai_koji' && (
                                            <TextField
                                                className={classes.kikaku}
                                                variant="outlined"
                                                size="small"
                                                name={`${item.name}_kikaku`}
                                                label="内容"
                                                defaultValue={item.kikaku}
                                            />
                                        )}
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            name={`${item.name}_bikou`}
                                            label="備考"
                                            defaultValue={item.bikou}
                                        />

                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className={classes.formRow}>
                            <Button variant="outlined" color="primary" onClick={handleAddItem(group.group)}><AddIcon />項目を追加する</Button>
                        </div>
                    </Fragment>
                ))
                }
                <Divider />
                <div className={classes.formRow}>
                    <Typography variant="h6" className={classes.groupName}>値引き</Typography>
                </div>
                <div className={classes.formRow}>
                    <Typography>値引き</Typography>
                    <TextField
                        type="number"
                        size="small"
                        className={classes.price}
                        variant="outlined"
                        name="nebiki"
                        defaultValue={initialNebiki > 0 ? initialNebiki : ""}
                        onChange={handleChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>-</Typography>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <span>円</span>
                </div>
                <div className={classes.formRow}>
                    <Button type="submit" variant="contained" color="secondary">保存</Button>
                    <Button variant="text" color="primary" onClick={handleCancel}>キャンセル</Button>
                </div>
            </form >
            <Paper className={classes.total}>
                <Typography>合計: <MitumoriSum initialTotal={initialTotal} ref={refTotal} />円</Typography>
            </Paper>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
    },
    formRow: {
        display: 'flex',
        alignItems: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    formRowCol: {
        display: 'flex',
        flexDirection: 'column',
    },
    formRowColLine: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
    },
    h6: {
        margin: theme.spacing(1),
    },
    select: {
        width: 200,
    },
    bikou: {
        width: 400,
        marginRight: theme.spacing(1),
    },
    quantity: {
        width: 100,
        marginRight: theme.spacing(1),
    },
    itemUnit: {
        marginRight: theme.spacing(1),
    },
    price: {
        width: 100,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    kikaku: {
        width: 200,
        marginRight: theme.spacing(1),
    },
    itemName: {
        [theme.breakpoints.up('md')]: {
            flexBasis: 200,
        },
    },
    itemNameField: {
        width: 150,
    },
    groupName: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    total: {
        position: "fixed",
        bottom: theme.spacing(5),
        right: theme.spacing(5),
        padding: theme.spacing(2),
    },
}))

export default MitumoriEdit

const parseNum = v => {
    if (!v) {
        return 0
    }
    try {
        return parseInt(v)
    } catch (error) {
        return 0
    }
}

const parseFloatNum = v => {
    if (!v) {
        return 0.0
    }
    try {
        return parseFloat(v)
    } catch (error) {
        return 0.0
    }
}

const ItemSum = forwardRef((props, ref) => {
    const { name, initialItemsTotals, sp } = props
    console.log('ItemSum', { name, initialItemsTotals })
    const initialTotal = useInitialItemsTotal(initialItemsTotals, name)
    const [total, setTotal] = useState(initialTotal)
    const classes = useStyles()
    useEffect(() => {
        ref.current = {
            ...ref.current,
            [name]: {
                setTotal,
            }
        }
    }, [name, ref])
    if (!total) {
        return <></>
    }
    if (sp) {
        return (
            <div className={classes.formRowColLine}>
                <Typography>&nbsp;=&nbsp;{total.toLocaleString()}円</Typography>
            </div>
        )
    } else {
        return <Typography>&nbsp;=&nbsp;{total.toLocaleString()}円</Typography>
    }
})

const useInitialItemsTotal = (initialItemsTotals, name) => {
    console.log({ initialItemsTotals, name })
    if (!initialItemsTotals) {
        return 0
    }
    if (initialItemsTotals[name]) {
        return initialItemsTotals[name]
    }
    return 0
}
