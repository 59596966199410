import { Route, Switch } from "react-router-dom"
import AppBar from "./AppBar"
import Drawer from './Drawer'
import Customer from "./Customer"
import {NavigationProvider} from './navigation'
import Project from './Project'
import ProjectCreate from './ProjectCreate'
import ProjectDetail from "./ProjectDetail"
import CustomerDetail from "./CustomerDetail"

const DefaultApp = () => {
    return (
        <NavigationProvider>
                <>
                    <AppBar />
                    <Drawer />
                    <Routes />
                </>
        </NavigationProvider>
    )
}

export default DefaultApp

const Routes = () => {
    return (
        <Switch>
            <Route path="/" exact component={Project} />
            <Route path="/customers" exact component={Customer} />
            <Route path="/customers/:id" exact component={CustomerDetail} />
            <Route path="/projects/new/customer=:customerId" component={ProjectCreate} />
            <Route path="/projects/new" component={ProjectCreate} />
            <Route path="/projects/:id" component={ProjectDetail} />
        </Switch>
    )
}