import { Button, InputAdornment, Menu, MenuItem, TextField } from "@material-ui/core"
import { useCallback, useRef, useState } from "react"

const SelectableTextField = ({ defaultValue, menuValues, ...otherProps }) => {
    const ref = useRef()
    const [value, setValue] = useState(defaultValue)
    const handleChange = useCallback(evt => {
        setValue(evt.target.value)
    }, [])
    const handleClickMenuItem = val => () => {
        setValue(val)
        setOpen(false)
    }
    const [open, setOpen] = useState(false)
    const handleClickButton = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <TextField
                value={value}
                onChange={handleChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button color="primary" onClick={handleClickButton} ref={ref}>選択</Button>
                        </InputAdornment>
                    )
                }}
                {...otherProps}
            />
            <Menu
                open={open}
                anchorEl={ref.current}
                onClose={handleClose}
                >
                    {menuValues.map(val => (
                        <MenuItem key={menuItemKey(val)} onClick={handleClickMenuItem(val)}>{menuItemLabel(val)}</MenuItem>
                    ))}
            </Menu>
        </>
    )
}

export default SelectableTextField

const menuItemLabel = val => {
    if (val === '') {
        return '(リセット)'
    }
    return val
}

const menuItemKey = val => {
    if (val === '') {
        return '__rest'
    }
    return val
}