import { makeStyles } from "@material-ui/core"

const Content = ({ children }) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={classes.toolbar}></div>
            {children}
        </div>
    )
}

export default Content

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(2),
            paddingLeft: theme.spacing(2) + 250,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
        },
    },
    toolbar: theme.mixins.toolbar,
}))
